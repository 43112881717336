import { React, useState, createContext } from "react";
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import Container from "./components/container/main";
import FetchData from "./components/data/fetch";
import Volume from "./components/data/vol";
import { isTouchScreen, isScreenLandscape } from "./components/data/screenchecker";
import Background from "./components/container/background/bg";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: 'locales/{{lng}}.json',
    }
});

const stateTemplate = {
  isPlaying: false,
  isShown: true,
  isLoading: false,
  audio: new Audio(),
  initData: {
    id: '',
    name: '',
    listeners: 0,
    current_track: {
      id: '',
      artist: '',
      title: '',
      duration: 0,
      artwork: '',
      preview: '',
      preview_video: '',
      startingAt: new Date(),
      endingAt: new Date(),
    },
    next_tracks: [],
    last_tracks: [],
  },
  volume: parseFloat(localStorage.getItem('volume') || '0.5'),
};

export const PlayerContext = createContext(stateTemplate);

export function App() {
  const [playerState, setPlayerState] = useState(stateTemplate);
  document.body.style.overflow = "hidden";
  document.childNodes[1].style.overflow = "hidden"; 
  document.body.style.height = "100%";
  document.childNodes[1].style.height = "100%";
  if (isTouchScreen && !isScreenLandscape()) {
    document.childNodes[1].style.fontSize = '32px';
    document.childNodes[1].style.backgroundColor = '#000000';
  }
  return (
      <div className="flex justify-center bg-black select-none">
        <PlayerContext.Provider value={{ playerState, setPlayerState }}>
          <FetchData />
          <Background />
          <Container />
          <Volume />
        </PlayerContext.Provider>
      </div>
  );
}