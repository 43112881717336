import { useContext } from "react";
import { artworkSize } from "../../basicInfo";
import { PlayerContext } from "../../../App";

export default function Background() {
    const { playerState } = useContext(PlayerContext);
    const video = playerState?.initData.current_track.preview_video || null;
    const artwork = playerState?.initData.current_track.artwork || null;
    const showBackground = () => {  
        let opacity = playerState?.isPlaying ? 'opacity-50' : 'opacity-20';
        let commonClassName = `${opacity} overflow-hidden object-cover transition-opacity blur-sm duration-600 ease-in select-none h-full w-full`;
        if (video) {
            return <video loop muted webkit-playsinline="true" playsInline autoPlay className={commonClassName} src={video} />;
        } else {
            return <img className={commonClassName} src={`${artwork}?width=${artworkSize}&height=${artworkSize}&sa=webp`} alt='background' />
        }
    }
    return (
        <div className={`${playerState?.isShown ? `flex` : `hidden`} z-0 absolute justify-center select-none bg-black top-0 left-0 border-0 portrait:h-[100vh] landscape:w-[100vw] landscape:h-[100vh] flex justify-center pointer-events-none`}>
            {showBackground()}
        </div>
    )
}