import { useContext } from "react";
import { CardBody, Button } from "@nextui-org/react";
import { Play, Pause, Loading } from "../media/icons";
import { spaceId } from "../../../basicInfo";
import Hls from "hls.js";
import { PlayerContext } from "../../../../App";

export default function PlayButton() {
    const { playerState, setPlayerState } = useContext(PlayerContext);
    let visibilityClass, btnInp;
    const clickOnButton = () => {
        try {
            if (playerState) {
                const audio = playerState.audio;
                const audioSrc = `https://listen.atomic.radio/${spaceId}.m3u8`;
                if (!playerState.isPlaying) {
                    audio.current = new Audio();
                    audio.current.volume = playerState.volume;
                    if (audio.current.canPlayType('application/vnd.apple.mpegurl')) {
                        audio.current.src = audioSrc;
                        setPlayerState({
                            ...playerState,
                            isLoading: true,
                        })
                        audio.current
                        .play()
                        .then(() => {
                            setPlayerState({
                                ...playerState,
                                isPlaying: true,
                                isLoading: false
                            })
                        })
                        .catch(() => {
                            setPlayerState({
                                ...playerState,
                                isPlaying: false,
                            })
                        });
                    } else if (Hls.isSupported()) {
                        const hls = new Hls();
                        hls.config.autoStartLoad = true;
                        hls.config.debug = true;
                        hls.config.enableWorker = true;
                        hls.config.startFragPrefetch = false;
                        hls.config.startLevel = -1;
                        hls.config.testBandwidth = true;
                        hls.config.lowLatencyMode = true;
                        hls.loadSource(audioSrc);
                        hls.attachMedia(audio.current);
                        hls.on(Hls.Events.MANIFEST_PARSED, () => {
                            setPlayerState({
                                ...playerState,
                                isLoading: true
                            })
                            audio.current
                                .play()
                                .then(() => {
                                    setPlayerState({
                                        ...playerState,
                                        isPlaying: true,
                                        isLoading: false
                                    })
                                })
                                .catch(() => {
                                    setPlayerState({
                                        ...playerState,
                                        isPlaying: false,
                                    })
                                });
                        });
                        hls.on(Hls.Events.ERROR, function (event, data) {
                        if (data.fatal) {
                            switch (data.type) {
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.log('fatal media error encountered, try to recover');
                                hls.recoverMediaError();
                                break;
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                console.error('fatal network error encountered', data);
                                break;
                            default:
                                hls.destroy();
                                console.error('unrecoverable fatal error encountered, destroying hls');
                                break;
                            }
                        }
                        });
                    }
                } else {
                    setPlayerState({
                        ...playerState,
                        isPlaying: false
                    })
                    audio.current.pause();
                    audio.current.src = '';
                }  
            }
        } catch (error) {
            setPlayerState({
                ...playerState,
                isPlaying: false,
                isLoading: false
            })
            console.log(error)
        }
    }
    navigator.mediaSession.setActionHandler('play', () => {clickOnButton()});
    navigator.mediaSession.setActionHandler('pause', () => {clickOnButton()});
    if (playerState) {
        if (playerState.isPlaying) {
            visibilityClass = `opacity-0 hover:transition-opacity hover:opacity-100 hover:ease-in duration-300 hover:duration-300`;
            btnInp = (
                <Button isIconOnly disableRipple onPress={() => clickOnButton()} className="bg-transparent w-3/5 h-3/5">
                    <div className="flex relative hover:visible inline-flex flex-col w-3/5 h-3/5 gap-2 items-center justify-center">
                        <Pause />
                    </div>
                </Button>
            )
        } else if ((!playerState.isPlaying) && (playerState.isLoading)) {
                btnInp = (
                    <Button isIconOnly disableRipple isDisabled className="bg-transparent w-3/5 h-3/5">
                        <div className="flex relative hover:visible inline-flex flex-col w-3/5 h-3/5 gap-2 items-center justify-center">
                            <Loading />
                        </div>
                    </Button>
                )
        } else {
                btnInp = (
                    <Button isIconOnly disableRipple onPress={() => clickOnButton()} className="bg-transparent w-3/5 h-3/5">
                        <div className="flex relative hover:visible inline-flex flex-col w-3/5 h-3/5 gap-2 items-center justify-center">
                            <Play />
                        </div>
                    </Button>
                )
        }
    }
    return (
        <CardBody className={`flex w-full h-full bg-black/30 justify-center items-center ${visibilityClass} absolute z-10`}>
            {btnInp}
        </CardBody>
    )
}