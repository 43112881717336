export const Search = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
            <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"></path>
        </svg>
    )
}

export const Play = () => {
    return (
        <svg width="722" height="800" viewBox="0 0 722 800" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M131.179 17.6394C59.054 -23.7323 0.580078 10.1599 0.580078 93.2791V706.662C0.580078 789.865 59.054 823.713 131.179 782.38L667.306 474.915C739.455 433.528 739.455 366.477 667.306 325.1L131.179 17.6394Z" fill="white"/>
        </svg>
    )
}

export const Pause = () => {
    return (
        <svg width="718" height="800" viewBox="0 0 718 800" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0185547 131.724V668.291C0.0185547 741.048 48.2226 800 107.691 800C167.165 800 215.398 741.045 215.398 668.291V131.724C215.398 59.0008 167.165 0.022956 107.691 0.022956C48.2226 -0.00012055 0.0185547 59.0008 0.0185547 131.724Z" fill="white"/>
            <path d="M610.277 0C550.815 0 502.611 58.9779 502.611 131.701V668.268C502.611 741.025 550.815 799.954 610.277 799.954C669.74 799.954 717.981 741.022 717.981 668.268V131.724C717.955 59.0009 669.74 0 610.277 0Z" fill="white"/>
        </svg>
    )
}

export const Loading = () => {
    return (
        <div className="relative flex w-full h-full">
            <i className="absolute w-full h-full rounded-full animate-spinner-ease-spin border-solid border-t-transparent border-l-transparent border-r-transparent border-5 border-b-white"></i>
            <i className="absolute w-full h-full rounded-full opacity-100 animate-spinner-linear-spin border-dotted border-t-transparent border-l-transparent border-r-transparent border-5 border-b-white"></i>
        </div>
    )
}
