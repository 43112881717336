import { ModalBody, Card, CardBody, Spacer } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
    const { t } = useTranslation();
    return (
        <ModalBody className="flex justify-center w-full">
            <p className="flex flex-col gap-1 font-extrabold text-center text-3xl">{t('aboutUs.name')}</p>
            <Spacer y={2} />
            <Card className="bg-black/40 text-white">
                <CardBody className="border-8 border-transparent">
                    <p className="text-xl" dangerouslySetInnerHTML={{__html: t('aboutUs.content')}} />
                </CardBody>
            </Card>
            <Spacer y={2} />
            <Card className="bg-black/40 text-white">
                <CardBody className="border-8 border-transparent">
                    <p className="text-xl" dangerouslySetInnerHTML={{__html: t('aboutUs.invite')}} />
                </CardBody>
            </Card>
        </ModalBody>
    )
}