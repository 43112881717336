import { PlayStream, Yandex, Status, Lock, Imprint, Telegram, YouTube, Discord, VK } from "../media/icons";

export const linkList = [
    {
        name: 'information.content.status',
        icon: <Status />,
        color: 'success',
        link: 'https://status.atomic.radio/'
    },
    {
        name: 'information.content.imprint',
        icon: <Imprint />,
        color: 'secondary',
        link: 'https://atomic.radio/legal/imprint'
    },
    {
        name: 'information.content.privacy',
        icon: <Lock />,
        color: 'danger',
        link: 'https://atomic.radio/legal/privacy'
    },
    {
        name: 'information.content.yandex',
        icon: <Yandex />,
        color: 'warning',
        link: 'https://yandex.com/profile/206999536388'
    },
    {
        name: 'information.content.stream',
        icon: <PlayStream />,
        color: 'primary',
        link: 'https://listen.atomic.radio/dopephonk/highquality'
    },
]

export const socialsList = [
    {
        name: 'Telegram',
        icon: <Telegram />,
        color: 'bg-[#0088cc]',
        link: 'https://t.me/atomicradio'
    },
    {
        name: 'VKontakte',
        icon: <VK />,
        color: 'bg-[#0077ff]',
        link: 'https://vk.com/atomicradio'
    },
    {
        name: 'Discord',
        icon: <Discord />,
        color: 'bg-[#5865f2]',
        link: 'https://discord.gg/ZvYGRS57cs'
    },
    {
        name: 'YouTube',
        icon: <YouTube />,
        color: 'bg-[#ff0000]',
        link: 'https://www.youtube.com/@atomicradioeu'
    },
]