import { Button, ModalHeader, ModalBody } from "@nextui-org/react";
import { socialsList } from "../info/lists";
import { isTouchScreen } from "../../data/screenchecker";
import { useTranslation } from "react-i18next";

export default function Socials() {
    const { t } = useTranslation();
    const listButton = socialsList.map(e => {
        return (
            <Button key={e.name} className={`flex ${isTouchScreen ? `w-full` : `w-2/4`} ${e.color} font-bold text-white`} onPress={() => {window.open(e.link, '_blank')}} size="lg">
                {e.icon} <p className="flex h-full items-center pt-1">{e.name}</p>
            </Button> 
        )
    })
    return (
        <>
            <ModalHeader className="flex flex-col font-extrabold text-center text-3xl">{t('socials.name')}</ModalHeader>
            <ModalBody className="flex justify-center items-center">
                {listButton}
            </ModalBody>
        </>
    )
}