import { io } from 'socket.io-client';
import { spaceId } from "../basicInfo";
import { useContext, useLayoutEffect } from 'react';
import { PlayerContext } from '../../App';

const socket = io('https://api.atomic.radio', { query: { token: undefined }, autoConnect: false, transports: ['websocket', 'polling'] });

export default function FetchData() {
    const { playerState, setPlayerState } = useContext(PlayerContext);

    useLayoutEffect(() => {
        socket.on('update_space', (data) => {
            if (data.id !== spaceId ) return;
            setPlayerState({
                ...playerState,
                initData: data
            });
        });
        socket.connect();
    }, [playerState, setPlayerState]);
    
}