import { Button, ModalHeader, ModalBody } from "@nextui-org/react";
import { linkList } from "../info/lists";
import { isTouchScreen } from "../../data/screenchecker";
import { useTranslation } from "react-i18next";

export default function Information() {
    const { t } = useTranslation();
    const listButton = linkList.map(e => {
        return (
            <Button key={e.name} className={`flex ${isTouchScreen ? `w-full` : `w-2/4`} font-bold`} color={e.color} onPress={() => {window.open(e.link, '_blank')}} size="lg" startContent={e.icon}>
                <p className="flex h-full items-center pt-1">{t(e.name)}</p>
            </Button>
        )
    })
    return (
        <>
            <ModalHeader className="flex flex-col gap-1 font-extrabold text-center text-3xl">{t('information.name')}</ModalHeader>
            <ModalBody className="flex justify-center items-center w-full">
                {listButton}
            </ModalBody>
        </>
    )
}