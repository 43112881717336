import { useContext, useEffect } from "react";
import { Progress } from "@nextui-org/react";
import { isTouchScreen } from "./screenchecker";
import { PlayerContext } from "../../App";

export default function Volume() {
    const { playerState, setPlayerState } = useContext(PlayerContext);
    useEffect(() => {
        if (playerState) {
            const handleVolume = (e) => {
                const delta = Math.max(-1, Math.min(1, e.deltaY));
                const step = 0.02;
                const newVolume = Math.max(0, Math.min(1, playerState.volume - delta * step));
                localStorage.setItem('volume', newVolume.toString());
                setPlayerState({
                    ...playerState,
                    volume: newVolume
                });
                playerState.audio.current.volume = newVolume;
            }
            window.addEventListener('wheel', handleVolume);
            return () => {
                window.removeEventListener('wheel', handleVolume);
            }
        }
    }, [playerState, setPlayerState]);
    return(
        <>
            {isTouchScreen ? <></> : 
                <Progress aria-label="Volume" classNames={{
                    base: `flex ${playerState?.isPlaying ? 'visible' : 'hidden'} h-0.5 absolute bottom-0 z-10`,
                    indicator: `bg-white`,
                    track: `bg-transparent`,
                }} 
                value={playerState?.volume * 100} 
            />}
        </>
    )
}