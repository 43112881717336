export const PlayStream = () => {
    return (
        <svg width="534" height="357" viewBox="0 0 534 357" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M266.666 111.896C303.485 111.896 333.333 141.743 333.333 178.562C333.333 215.381 303.485 245.228 266.666 245.228C229.847 245.228 200 215.381 200 178.562C200 141.743 229.847 111.896 266.666 111.896ZM150 178.562C150 201.8 156.793 223.45 168.503 241.636C175.978 253.245 176.256 268.973 166.493 278.736V278.736C156.73 288.499 140.721 288.591 132.518 277.485C112.081 249.816 100 215.6 100 178.562C100 141.525 112.081 107.308 132.518 79.6394C140.721 68.5332 156.73 68.6253 166.493 78.3886V78.3886C176.256 88.1517 175.978 103.879 168.503 115.488C156.793 133.674 150 155.324 150 178.562ZM383.333 178.562C383.333 155.324 376.539 133.674 364.829 115.487C357.355 103.879 357.077 88.1518 366.84 78.3888V78.3888C376.603 68.6259 392.61 68.5335 400.813 79.6391C421.251 107.307 433.333 141.524 433.333 178.562C433.333 215.601 421.251 249.817 400.814 277.485C392.61 288.591 376.602 288.499 366.839 278.736V278.736C357.076 268.972 357.355 253.246 364.829 241.637C376.539 223.451 383.333 201.8 383.333 178.562ZM50 178.562C50 229.404 67.5116 276.157 96.8331 313.116C105.415 323.933 105.545 339.683 95.7821 349.446V349.446C86.0189 359.21 70.0869 359.278 61.2748 348.648C23.0048 302.485 0 243.21 0 178.562C0 113.914 23.0048 54.6388 61.2748 8.47588C70.0869 -2.15364 86.0189 -2.08531 95.7821 7.67788V7.67788C105.545 17.4411 105.414 33.1912 96.8331 44.0078C67.5116 80.9671 50 127.72 50 178.562ZM483.333 178.562C483.333 127.72 465.821 80.967 436.499 44.0078C427.918 33.1911 427.787 17.4411 437.551 7.67789V7.67789C447.314 -2.0853 463.246 -2.15364 472.058 8.47588C510.328 54.6388 533.333 113.914 533.333 178.562C533.333 243.21 510.328 302.485 472.058 348.648C463.246 359.278 447.314 359.21 437.551 349.446V349.446C427.787 339.683 427.918 323.933 436.5 313.116C465.821 276.157 483.333 229.404 483.333 178.562Z" fill="white" />
        </svg>
    )
}

export const Yandex = () => {
    return (
        <svg width="700" height="800" viewBox="0 0 700 800" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M517.886 0H404.224C289.069 0 172.872 85.0344 172.872 275.009C172.872 373.412 214.577 450.052 291.027 493.638L151.102 746.905C144.468 758.883 144.294 772.458 150.636 783.217C156.829 793.725 168.152 800 180.914 800H251.697C267.777 800 280.314 792.228 286.327 778.613L417.522 522H427.097V768.017C427.097 785.355 441.727 800 459.044 800H520.879C540.299 800 553.857 786.441 553.857 767.025V35.0047C553.86 14.3953 539.069 0 517.886 0ZM427.097 408.009H410.207C344.714 408.009 305.616 354.55 305.616 265.005C305.616 153.662 355.007 113.992 401.232 113.992H427.097V408.009Z" fill="black"/>
        </svg>
    )
}

export const Lock = () => {
    return (
        <svg width="800" height="368" viewBox="0 0 800 368" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M798.173 45.95C793.558 13.6594 793.558 -9.41246 775.1 4.43129C756.647 18.275 642.834 47.0047 521.347 59.7954C464.348 65.8016 425.959 82.5516 399.998 102.392C374.036 82.5532 335.655 65.8016 278.65 59.7954C157.162 47.0047 43.3469 18.275 24.8953 4.43129C6.43749 -9.41246 6.43749 13.6594 1.82811 45.95C-6.65626 105.334 11.05 272.03 126.397 332.006C248.103 395.291 334.012 359.681 375.537 336.622C384.681 331.534 392.73 326.339 399.997 321.167C407.264 326.339 415.311 331.534 424.456 336.622C465.983 359.681 551.897 395.291 673.603 332.006C788.944 272.03 806.652 105.334 798.173 45.95ZM248.813 262.861C196.834 258.852 144.852 206.867 144.852 162.891C144.852 162.891 224.827 166.886 260.809 170.884C296.794 174.881 324.791 202.872 324.791 218.87C324.789 234.858 300.795 266.845 248.813 262.861ZM551.183 262.861C499.205 266.845 475.211 234.858 475.211 218.87C475.211 202.872 503.202 174.88 539.186 170.884C575.17 166.888 655.144 162.891 655.144 162.891C655.144 206.867 603.167 258.852 551.183 262.861Z" fill="white" />
        </svg>
    )
}

export const Status = () => {
    return (
        <svg width="800" height="700" viewBox="0 0 800 700" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M352.566 34.1886C367.07 -9.32417 426.479 -11.302 445.066 28.2551L447.434 34.1886L599.95 491.9L652.566 334.189C658.753 315.628 675.106 302.559 694.198 300.336L700 300H750C777.614 300 800 322.386 800 350C800 375.642 780.698 396.775 755.831 399.664L750 400H736L647.434 665.811C632.93 709.324 573.521 711.302 554.934 671.745L552.566 665.811L400 208.05L297.434 515.811C283.374 557.991 226.398 561.564 206.202 524.188L203.576 518.57L148.05 379.8L144.509 384.166C136.551 392.666 125.767 398.228 113.973 399.643L108 400H50C22.3858 400 0 377.614 0 350C0 324.358 19.302 303.225 44.1689 300.336L50 300H74.75L103.901 230.638C120.393 191.37 174.328 189.955 193.775 225.778L196.424 231.43L245.7 354.7L352.566 34.1886Z" fill="black"/>
        </svg>
    )
}

export const Imprint = () => {
    return (
        <svg width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path fill="white" fillRule="evenodd" d="M16,8 C16,12.4183 12.4183,16 8,16 C3.58172,16 0,12.4183 0,8 C0,3.58172 3.58172,0 8,0 C12.4183,0 16,3.58172 16,8 Z M9,5 C9,5.55228 8.55229,6 8,6 C7.44772,6 7,5.55228 7,5 C7,4.44772 7.44772,4 8,4 C8.55229,4 9,4.44772 9,5 Z M8,7 C7.44772,7 7,7.44772 7,8 L7,11 C7,11.5523 7.44772,12 8,12 C8.55229,12 9,11.5523 9,11 L9,8 C9,7.44772 8.55229,7 8,7 Z"/>
        </svg>
    )
}

export const VK = () => {
    return (
        <svg width="279" height="279" viewBox="0 0 279 279" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_24_7" maskUnits="userSpaceOnUse" x="0" y="0" width="279" height="279">
                <path d="M279 0H0V279H279V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_24_7)">
                <path fillRule="evenodd" clipRule="evenodd" d="M19.6121 19.6121C0 39.2243 0 70.7896 0 133.92V145.08C0 208.21 0 239.776 19.6121 259.388C39.2243 279 70.7896 279 133.92 279H145.08C208.21 279 239.776 279 259.388 259.388C279 239.776 279 208.21 279 145.08V133.92C279 70.7896 279 39.2243 259.388 19.6121C239.776 0 208.21 0 145.08 0H133.92C70.7896 0 39.2243 0 19.6121 19.6121ZM47.0818 84.8632C48.5929 157.403 84.8626 200.997 148.451 200.997H152.056V159.495C175.422 161.82 193.091 178.909 200.182 200.997H233.198C224.13 167.982 200.298 149.73 185.418 142.755C200.298 134.153 221.223 113.228 226.222 84.8632H196.229C189.719 107.881 170.423 128.805 152.056 130.782V84.8632H122.062V165.308C103.462 160.658 79.9804 138.106 78.9341 84.8632H47.0818Z" fill="white"/>
            </g>
        </svg>
        
    )
}

export const Telegram = () => {
    return (
        <svg width="800px" height="800px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="M41.4193 7.30899C41.4193 7.30899 45.3046 5.79399 44.9808 9.47328C44.8729 10.9883 43.9016 16.2908 43.1461 22.0262L40.5559 39.0159C40.5559 39.0159 40.3401 41.5048 38.3974 41.9377C36.4547 42.3705 33.5408 40.4227 33.0011 39.9898C32.5694 39.6652 24.9068 34.7955 22.2086 32.4148C21.4531 31.7655 20.5897 30.4669 22.3165 28.9519L33.6487 18.1305C34.9438 16.8319 36.2389 13.8019 30.8426 17.4812L15.7331 27.7616C15.7331 27.7616 14.0063 28.8437 10.7686 27.8698L3.75342 25.7055C3.75342 25.7055 1.16321 24.0823 5.58815 22.459C16.3807 17.3729 29.6555 12.1786 41.4193 7.30899Z" />
        </svg>
    )
}

export const YouTube = () => {
    return (
        <svg width="700" height="489" viewBox="0 0 700 489" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M662.968 37.0819C673.792 47.9063 681.595 61.3753 685.6 76.15C700 130.9 700 244.5 700 244.5C700 244.5 700 358.1 685.6 412.85C681.595 427.625 673.792 441.094 662.968 451.918C652.144 462.742 638.675 470.545 623.9 474.55C569.1 489 350 489 350 489C350 489 130.9 489 76.1 474.55C46.15 466.5 22.5 442.85 14.4 412.85C0 358.65 0 244.5 0 244.5C0 244.5 0 130.9 14.4 76.15C22.5 46.15 46.1 22.5 76.1 14.45C130.9 1.90735e-06 350 0 350 0C350 0 569.1 1.90735e-06 623.9 14.45C638.675 18.4551 652.144 26.2576 662.968 37.0819ZM462.5 245L279.5 350.5V139.5L462.5 245Z" fill="white"/>
        </svg>
    )
}

export const Discord = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
            <path fill="#fff" d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
        </svg>
    )
}