import { useContext, useState } from "react";
import { useDisclosure } from "@nextui-org/react";
import { Button, Modal, ModalContent } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import Logo from './media/logo.svg';
import { isTouchScreen } from "../data/screenchecker";
import Player from "./player/main";
import AboutUs from "./content/about";
import Socials from "./content/socials";
import TrackHistory from "./content/history";
import Information from "./content/information";
import { PlayerContext } from "../../App";

export default function Container() {
    const { t } = useTranslation();
    const { playerState, setPlayerState } = useContext(PlayerContext);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [key, setKey] = useState('');
    const commonClassNameButton = "text-white bg-transparent"
    const handleOpen = (content) => {
        setKey(content);
        onOpen();
    }
    const openModal = (key) => {
        if (key === 'about') {
            return <AboutUs />
        } else if (key === 'socials') {
            return <Socials />
        } else if (key === 'history') {
            return <TrackHistory />
        } else if (key === 'information') {
            return <Information />
        }
    }
    const handleClick = () => {
        if (playerState.isShown) {
            setPlayerState({
                ...playerState,
                isShown: false
            })
        } else {
            setPlayerState({
                ...playerState,
                isShown: true
            })
        }
    }
    if (playerState.id === null) {
        return (
            <Player />
        )
    } else {
        return (
            <div className={`flex flex-col ${playerState?.isShown ? `` : `bg-black`} justify-between w-full h-full border-8 border-transparent absolute`}>
                <div className="flex justify-between">      
                    <Button className={commonClassNameButton} onPress={() => handleOpen('about')} color="default" size="lg">{t('aboutUs.name')}</Button>
                    <img className={`${isTouchScreen ? `w-48` : `w-56`} z-10`} onClick={handleClick} src={Logo} alt='logotype' />
                    <Button className={commonClassNameButton} onPress={() => handleOpen('socials')} color="default" size="lg">{t('socials.name')}</Button>
                </div>
                <Player />
                <div className="flex justify-between">
                    <Button className={commonClassNameButton}  onPress={() => handleOpen('history')} color="default" size="lg">{t('trackHistory.name')}</Button>
                    <Button className={commonClassNameButton} onPress={() => handleOpen('information')} color="default" size="lg">{t('information.name')}</Button>
                </div>
                <Modal hideCloseButton shouldBlockScroll size='4xl' backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange}>
                    <ModalContent className="bg-transparent select-none shadow-none overflow-hidden w-3/4 text-white">
                        {(onClose) => (openModal(key))}
                    </ModalContent>
                </Modal>
          </div>
        )
    }
}