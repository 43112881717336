import { Card, Spacer, Image } from "@nextui-org/react";
import PlayButton from "./playButton";
import { artworkSize } from "../../../basicInfo";

const localList = {
    size: {
        card: {
            side: 'w-64 h-96'
        }
    },
}

function Artwork(props) {
    return (
        <Image
            alt="cover"
            className={`flex object-cover rounded-none bg-transparent`}
            height={props.size}
            src={props.src}
            width={props.size}
        /> 
    )
}

function Trackname(props) {
    const initData = props?.initData || null;
    let textSize, spaceSize;
    const search = () => {
        let searchLink = 'https://atomic.radio/tracks/' + initData.id;
        window.open(searchLink, '_blank');
        return;
    };
    if (props.main) {
        textSize = 'text-4xl';
        spaceSize = 1;
    } else {
        textSize = 'text-2xl';
        spaceSize = 0;
    }
    return (
        <>
        {initData ? 
            <div className="text-white text-center relative cursor-pointer z-10" onClick={search}>
                <p className={`${textSize} font-bold`}>{initData.title}</p>
                <Spacer y={spaceSize}/>
                <p className={`${textSize} opacity-75`}>{initData.artist}</p>
            </div>
        : 
            <></>
        }
        </>
    )
}

/*function ProgressBar(props) {
    return (
        <Progress aria-label="Progress Bar"
            classNames={{
                base: `max-w-md h-full`,
                indicator: `bg-white`,
                track: `bg-zinc-700 rounded-none`,
            }}
            isIndeterminate={props.isPlaying ? true : false}
            value={props.isPlaying ? props.amount : 0}
        />
    )
}*/

export function NowPlaying(props) {
    let initData = props?.initData || 'no data';
    if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
            title: initData.title,
            artist: initData.artist,
            artwork: [
                {
                    src: initData.artwork + '?width=1000&height=1000&sa=webp',
                    sizes: '1000x1000',
                    type: 'image/webp',
                },
                {
                    src: initData.artwork + '?width=1000&height=1000&sa=jpg',
                    sizes: '1000x1000',
                    type: 'image/jpeg',
                },
                {
                    src: initData.artwork + `?width=${artworkSize}&height=${artworkSize}&sa=webp`,
                    sizes: `${artworkSize}x${artworkSize}`,
                    type: 'image/webp',
                },
                {
                    src: initData.artwork + `?width=${artworkSize}&height=${artworkSize}&sa=jpg`,
                    sizes: `${artworkSize}x${artworkSize}`,
                    type: 'image/jpeg',
                },
                {
                    src: initData.artwork + '?width=150&height=150&sa=webp',
                    sizes: '150x150',
                    type: 'image/webp',
                },
                {
                    src: initData.artwork + '?width=150&height=150&sa=jpg',
                    sizes: '150x150',
                    type: 'image/jpeg',
                },
            ],
        });
    }
    return (
        <div className={`flex flex-col drop-shadow-md justify-center items-center z-10`}>
            <Card className={`border-none drop-shadow rounded-2xl w-[400px] h-[400px] bg-transparent`}>
            {initData ?
                <>
                    <Artwork src={`${initData.artwork}?width=${artworkSize}&height=${artworkSize}&sa=webp`}/>
                    <PlayButton />
                    {/*<ProgressBar amount={50} isPlaying={props.playerState.isPlaying} />*/}
                </>
            : 
                <></>
            }
            </Card>
            <Spacer y={8} />
            <Trackname main initData={initData} />
        </div>
    )
}

export function SideMetas(props) {
    let initData = props.initData;
    let commonClassName = 'w-64 h-64';
    return (
        <div className={`flex portrait:hidden drop-shadow-md landscape:visible flex-col ${localList.size.card.side} justify-center items-center`}>
            <Card className="border-none rounded-2xl bg-transparent">                   
                {initData ?
                    <Artwork className={commonClassName} src={`${initData.artwork}?width=${artworkSize}&height=${artworkSize}&sa=webp`}/>
                : 
                    <></>
                }
            </Card>
            <Spacer y={6} />
            <Trackname initData={initData} />
        </div>
    )
}