import { useContext } from "react";
import { Avatar, ModalBody, Card, CardBody, Spacer } from "@nextui-org/react";
import { isTouchScreen } from "../../data/screenchecker";
import { PlayerContext } from "../../../App";

const TimeFull = (time) => {
    if (time >= 10) {
        return time;
    } else {
        return `0${time}`;
    }
}

const search = (trackId) => {
    let searchLink = 'https://atomic.radio/tracks/' + trackId;
    window.open(searchLink, '_blank');
    return;
};

function TrackPosition(props) {
    const initData = props?.initData;
    let time = new Date(initData?.startingAt)
    let timer = `${TimeFull(time.getHours())}:${TimeFull(time.getMinutes())}`
    return (
        initData === undefined ? 
            <></>
        :
            <div className={`flex flex-row items-center ${isTouchScreen ? `w-full` : `w-3/4`}`}>
                <span className="text-lg leading-4 w-16 font-normal"><Spacer y={1} />{timer}</span>
                <Spacer x={4} />
                <Card className={`flex flex-row justify-left items-center ${props.isPlaying ? 'bg-black/80' : 'bg-black/40'} w-full`} shadow="sm" radius="lg">
                    <Avatar className="bg-transparent" src={`${initData.artwork}?width=60&height=60&sa=webp`} size="lg" radius="lg" />
                    <CardBody className="border-0 w-2/4 cursor-pointer text-white pt-0 pb-0 pl-4 pr-4" onClick={() => {search(initData.id)}}>
                            <Spacer y={1} />
                            <p className={`font-semibold tracking-wide text-lg leading-none`}>
                                {initData.title}
                            </p>
                            <p className={`font-base tracking-wide opacity-75 text-sm leading-none`}>
                                {initData.artist}
                            </p>
                    </CardBody>
                </Card>
            </div>
    )
}

export default function TrackHistory() {
    const { playerState } = useContext(PlayerContext);
    let initData = playerState?.initData;
    const lastTracks = initData.last_tracks.map(e => {
        return <TrackPosition key={e.id} initData={e} titleSize='font-normal' artistSize='font-extralight' />
    })
    console.log(playerState)
    return (
        playerState?.initData?.id === undefined && playerState?.initData === undefined ?
            <></>
        :
        <ModalBody shouldBlockScroll className="flex flex-col justify-center items-center">
            <TrackPosition key={initData?.next_tracks[0]?.id} initData={initData?.next_tracks[0]} />
            <TrackPosition isPlaying key={initData?.id} initData={initData?.current_track} />
            {lastTracks}
        </ModalBody>
    )
}