import { useContext } from "react";
import { Spacer }  from "@nextui-org/react";
import { SideMetas, NowPlaying } from "./content/onScreenMeta";
import { isTouchScreen } from "../../data/screenchecker";
import { PlayerContext } from "../../../App";

export default function Player() {
    const { playerState, setPlayerState } = useContext(PlayerContext);
    const initData = playerState?.initData || null;
    const metaData = {
        currentTrack: initData?.current_track,
        prevTrack: initData?.last_tracks[0],
        nextTrack: initData?.next_tracks[0],
    };
    return (
        <div className="flex flex-col">
            <div className="flex flex-col items-center">
                <Spacer y={4} />
                <div className={`flex flex-row justify-center items-center`}>
                    {isTouchScreen || window.screen.width < 1000 ? <></> : <><Spacer x={4} /><SideMetas initData={metaData.prevTrack} /></>}
                    <Spacer x={24} />
                    <NowPlaying
                        audio={playerState?.audio}
                        playerState={playerState} 
                        setPlayerState={setPlayerState}
                        initData={metaData.currentTrack}
                    />
                    <Spacer x={24} />
                    {isTouchScreen || window.screen.width < 1000 ? <></> : <><SideMetas initData={metaData.nextTrack} /><Spacer x={4} /></>}
                </div>
                <Spacer y={4} />
            </div>
        </div>
    )
}